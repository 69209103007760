import { Component } from '@angular/core';
import { CsiConfig } from '@qivos/qivoscloud-ion-components/lib/models/csi.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {}

  startingPageConfiguration: CsiConfig = {
    logo: '',
    welcomeMsg: 'Η γνώμη σου είναι σημαντική!',
    title: 'Αγαπητό padu μέλος',
    mainText:
      'Σκάναρες μια απόδειξη! Απόλαυσες την τελευταία σου επίσκεψη στο κατάστημα; Θα θέλαμε την άποψή σου, έτσι ώστε να βελτιωθεί η εμπειρία σου! Μόνο 2’ για σε 4 απλές ερωτήσεις!',
    questionnaireUrlText: 'ΑΣ ΞΕΚΙΝΗΣΟΥΜΕ',
    footerText:
      'Το ερωτηματολόγιο θα παραμείνει διαθέσιμο για 14 ημέρες από την ημερομηνία αποστολής του μηνύματος.',
    questionsData: [
      'Πώς θα αξιολογούσες την ποιότητα των προϊόντων που αγόρασες σε σύγκριση με την τιμή που καταβλήθηκε;',

      'Πως θα βαθμολογούσες την ποιότητα της εξυπηρέτησής σου κατά την παραμονή σου στο κατάστημά τους;',

      'Πως θα αξιολογούσες την γενική σου εμπειρία στο κατάστημα (μουσική, κλιματισμός, φωτισμός, καθαριότητα);',
    ],
    numericQuestionDescription:
      'Με κλίμακα 1-10, θα πρότεινες το κατάστημα σε κάποιον φίλο σου;',

    successMsg: 'Σε ευχαριστούμε!',
    successText: 'Συνέχισε να σκανάρεις και να κερδίζεις με κάθε απόδειξη!',
    expiredMsg: 'Λυπούμαστε',
    expiredText: 'Αυτή η σελίδα έχει λήξει',
    submitButtonText: 'ΑΠΟΣΤΟΛΗ',
    submitButtonColorText: '#ed1a23',
    siteUrl: '',
    account: 'padu',
    language: 'el',
    formId: 'PADU',
    iconsValue: ['ΠΟΛΥ ΚΑΚΗ', 'ΚΑΚΗ', 'ΜΕΤΡΙΑ', 'ΚΑΛΗ', 'ΕΞΑΙΡΕΤΙΚΗ'],
  };
}
